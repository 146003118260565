// IMPORTS
@import (reference) "../settings.less";

@media (max-width: 600px) {
    .detail-share-links-wrapper span {
        width: 100%;
    }

    .goshop-modal-body {
        padding: 10px;
    }

    .payment-channels-modal .goshop-modal-body .wrapper {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .order-buttons-wrapper .wrapper {

        > * {
            flex-grow: 1;

            .return-button {
                width: 100%;
            }
        }
    }

    .checkout-tab li {

        &.active span {
            position: relative;
            top: 1px;
        }
    }

    .cart-promo-code {
        width: 100%;
    }

    .cart-header {
        gap: 10px;

        .logo {
            flex-shrink: 1;
            max-width: 40%;

            img {
                height: auto;
                width: auto;
                max-height: 40px;
                max-width: 100%;
            }
        }

        .cart-back-link {
            white-space: nowrap;
            flex-shrink: 0;
        }
    }

    .check-person {
        gap: 10px;
    }

    form .row-wrapper.address-wrapper,
    form .row-wrapper.city-wrapper {
        grid-template-columns: 1fr;
        gap: 0;
    }

    .stars-rating.average-rating {
        top: -2px;
    }

    @media (max-width: 550px) {
        .gs-tabs {

            nav {
                flex-direction: column;

                button {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                }
            }
        }
    }

    .user-custom-content {

        iframe {
            max-width: 100%;
        }
    }

    .privacy-box {
        padding: 10px;
        gap: 10px;
    }

    .privacy-box__header {
        gap: 10px;

        img {
            height: 20px;
        }

        strong {
            font-size: 14px;
        }
    }

    .privacy-box__actions {
        flex-wrap: wrap;

        button {
            min-height: 35px;
        }
    }

    .privacy-box__actions button.privacy-accept-all {
        width: 100%;
        order: -1;
        flex: 0 0 auto;
        min-height: 45px;
    }

    .pagination {
        gap: 4px;

        a {
            width: 30px;
            height: 30px;
        }
    }

    .address-select-list {
        width: ~"calc(100vw - 42px)";
        overflow-x: auto;
        flex-wrap: nowrap;
        padding-top: 10px;
    }

    .tippy-box .tippy-content {
        padding: 10px;
    }

    .infobox {
        padding: 10px;

        > * {
            width: 100%;
        }

        &:before {
            font-size: 80px;
        }
    }

    #breadcrumb {
        display: flex;
        margin-left: -10px;
        width: ~"calc(100% + 20px)";
        justify-content: flex-start;
        position: relative;

        .breadcrumb-end {
            display: block;
            position: absolute;
            top: 0;
            top: 0;
            height: 100%;
            width: 20px;
            pointer-events: none;

            &:nth-of-type(1) {
                left: -7px;
                background-image: linear-gradient(to left, rgba(255, 255, 255, 0), rgb(255, 255, 255), rgb(255, 255, 255));
            }

            &:nth-of-type(2) {
                right: -7px;
                background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgb(255, 255, 255), rgb(255, 255, 255));
            }
        }

        ul {
            flex-wrap: nowrap;
            overflow-y: auto;
            justify-content: flex-start;
            flex-direction: row-reverse;
            padding-bottom: 5px;
        }

        li {
            white-space: nowrap;

            &:last-of-type {
                padding-right: 10px;
            }

            &:first-of-type {
                padding-left: 10px;
            }

            &:nth-of-type(1) {
                order: 10;
            }

            &:nth-of-type(2) {
                order: 9;
            }

            &:nth-of-type(3) {
                order: 8;
            }

            &:nth-of-type(4) {
                order: 7;
            }

            &:nth-of-type(5) {
                order: 6;
            }
        }
    }

    .captcha-wrapper {

        > div {
            width: 268px !important;
            overflow: hidden;
            
            iframe {
                transform-origin: left top;
                transform: scale(.87);
            }
        }
    }

    .address-select-list li:not(.no-addresses) {
        max-width: 100%;
        min-width: 75%;
    }

    #gsGalleryContainer > a {
        width: 100%;
    }

    .navigation-wrapper {
        padding-top: 0;
        border-top: 0;
    }

    .goshop-modal-header {
        font-size: 16px;
        padding-right: 50px;
        line-height: 1.2;
        height: 50px;

        &:before {
            width: 40px;
            height: 40px;
        }
    }

    .goshop-modal-close {
        font-size: 14px;
        width: 50px;
        height: 50px;
    }

    .cart-modal .goshop-modal-body {

        .cart-popup-row-wrapper {
            flex-direction: column;
            align-items: center;
        }

        figure {
            height: 80px;
            margin: 0 0 10px;
            width: 100%;
        }

        section {
            text-align: center;
        
            figure {
                width: 100%;
                height: 80px;
                margin: 0 0 10px;
            }

            > div {
                width: 100%;

                > strong {
                    font-size: 16px;
                }
            }
        }

        .goshop-modal-footer {
            gap: 10px;

            > * {
                width: 100%;
                text-align: center;

                &:nth-child(2) {
                    order: -1;
                }
            }
        }
    }

    .shop-buttons-wrapper {
        gap: 10px;

        > * {
            width: 100%;
            text-align: center;

            &:nth-child(2) {
                order: -1;
            }
        }
    }

    .availability-modal-wrapper .wrapper {
        justify-content: center;
        gap: 10px;

        > p {
            text-align: center;
        }

        > .availability-product {
            justify-content: center;
        }
    }

    .availability-modal-wrapper .availability-product {
        gap: 10px;
        padding: 10px 0;
    }

    .availability-modal-wrapper .input-wrapper {
        flex-wrap: wrap;

        > * {
            width: 100%;
        }
    }

    .check-person {

        > label {
            width: 100%;
        }

        .check-simple:first-of-type {
            margin-left: 0;
        }
    }

    .login-form > .signup-wrapper {
        width: 100%;
        padding-right: 0;
        border-right: 0;
    }

    .login-form > .signup-wrapper:nth-child(2) {
        padding-left: 0;
        padding-top: 20px;
        margin-top: 20px;
        border-top: 1px solid @border-color;
    }

    .cms-subarticles-wrapper {
        columns: 1 !important;
    }

    .user-orders-list .order-header .buttons-wrapper {
        position: static;
        float: left;
        clear: both;
        margin-top: 10px;
        width: 100%;
        flex-direction: row;

        a {
            padding: 5px;
        }
    }

    .order-detailed-info .item-box-large th:nth-child(2),
    .order-detailed-info .item-box-large td:nth-child(2) {
        display: none;
    }

    .login-form.logged .logged-header h2 {
        font-size: 18px;
    }

    .product-images {
        width: 100%;
    }

    .product-images .size-full {
        width: 100%;
        height: auto;
        aspect-ratio: 1;
    }

    .detail-amount {
        width: 100%;
        margin: 0 0 10px;
    }

    .thread-messages .message {
        margin-bottom: 20px;
    }

    .thread-messages .message header {
        flex-wrap: wrap;
        gap: 5px;
    }

    .thread-messages .message header .message-name {
        font-size: 14px;
    }

    .message-time {
        width: 100%;
        margin-top: -5px;
    }

    .thread-messages .message.client-response header .message-time {
        text-align: right;
    }

    .payment-channels-modal .goshop-modal .wrapper {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    .single-pill {
        padding: 2px 5px;
    }

    // SHOP SPECIFIC //////////////////////////////////////////////////////////////
    .gs-tabs .products-list .product:nth-child(4) {
        .grid();
    }

    .pagination a:after {
        font-size: 14px;
    }

    @media (max-width: 500px) {
        .header-bar {

            .header-bar-left {
                display: block;

                .phone {
                    display: none;
                }
            }
        }
    }

    @media (max-width: 340px) {
        .header-bar .header-bar-right {
            gap: 8px;
        }

        .shop-header-container .header-bar a,
        .shop-header-container .header-bar .header-location-trigger {
            gap: 5px;
        }

        .header-bar .log-button {
            gap: 4px !important;
        }
    }

    .shop-header-container {
        grid-template-columns: minmax(0, 1fr);

        .header-bar {
            grid-column: 1 / 2;
            grid-row: 1 / 2;
        }

        .logo {
            grid-column: 1 / 2;
            grid-row: 2 / 3;
            margin: 0 auto;
        }

        .header-tools-wrapper {
            grid-column: 1 / 2;
            grid-row: 3 / 4;
            width: 100%;
            justify-self: initial;
            align-self: initial;
            justify-content: space-evenly;
            margin: 10px 0 -2px 0;
        }
    }

    .mainpage-greeting-wrapper {
        gap: 10px;
    }

    .main-banner .text-wrapper {
        padding: 12px 16px;
        max-width: 90%;
        bottom: 8px;
        left: 8px;

        > b {
            font-size: 15px;
        }

        > span {
            display: none;
        }
    }

    .main-banner .swiper-pagination.banner-pagination {
        width: 24px;

        .swiper-pagination-bullet {
            height: 18px;
        }
    }

    .promo-banners-wrapper {
        gap: 10px;
    }

    .mainpage-greeting .headline {
        font-size: 18px !important;
    }

    .alternating-banners-wrapper {
        gap: 20px;
    }

    .alternating-banner {
        flex-direction: column;

        figure {
            width: 100%;
            max-width: 460px;
            margin: 0 auto;
            position: static;
            order: -1 !important;
        }

        .text-wrapper {
            margin: 10px 0 0 0;
            padding: 0 !important;
            width: 100%;
            max-width: 460px;

            > b {
                font-size: 18px;
            }
        }
    }

    @media (max-width: 420px) {
        #footer .map-wrapper {
            width: 100%;
        }
    }

    .footer-item >
    strong {
        font-size: 16px;
    }

    #cart-box, #wishlist-box, .search-engine-trigger, .tree-trigger {
        padding-top: 26px;
    }

    .dropdown-picker,
    .filter-trigger,
    .dropdown-button {
        min-width: 0;
        white-space: nowrap;
    }

    .filter-trigger {
        width: 100%;
    }

    .dropdown-picker {
        flex-grow: 1;
    }

    .dropdown-picker:before,
    .filter-trigger:before {
        left: 2px;
    }

    .filter-trigger,
    .dropdown-button {
        padding: 0 30px 0 10px;

        svg {
            right: 5px;
            transform: translateY(-50%) scale(.8);
        }

        &:after {
            width: 30px;
        }
    }

    .dropdown-button {
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
    }

    .dropdown-picker {

        &[data-dropdown-type="sort"] {
            width: calc(60% - 5px);

            .dropdown-listing {
                left: 0;
            }
        }

        &[data-dropdown-type="pageSize"] {
            width: calc(40% - 5px) !important;

            .dropdown-listing {
                left: auto;
                right: 0;
            }
        }
    }

    @media (max-width: 380px) {
        .dropdown-picker {

            &[data-dropdown-type="sort"] {
                width: calc(50% - 5px);
            }

            &[data-dropdown-type="pageSize"] {
                width: calc(50% - 5px) !important;
            }
        }
    }

    #detail-images {
        gap: 20px;
    }

    #detail-images .swiper-button-prev,
    #detail-images .swiper-button-next {
        width: 32px;
        height: 32px;
        margin-top: -25px !important;

        &:after {
            font-size: 16px;
        }
    }

    @media (max-width: 440px) {
        .detail-under-headline {
            flex-direction: column;
            align-content: center;
            gap: 3px;

            > * {
                margin: 0 auto !important;
            }

            span[data-role="sku"] {
                border-left: none;
                padding: 0;
            }

            .stars-rating.average-rating {
                top: -1px;
            }
        }
    }

    .headline {
        font-size: 18px !important;
    }

    .detail-accordion-trigger:after {
        font-size: 16px;
    }

    #detail-tabs
    nav > button {
        font-size: 20px;
    }

    .under-comment {
        .grid();
        grid-template-columns: 8fr 2fr;
        gap: 4px;

        .author {
            grid-row: 1 / 2;
            grid-column: 1 / 2;
        }

        .stars-rating {
            margin-left: auto;
            grid-row: 1 / 2;
            grid-column: 2 / 3;
        }

        .purchase-confirmed {
            grid-row: 2 / 3;
            grid-column: 1 / 2;
            border-left: none;
            margin: 0;
            padding: 0;
        }

        .added-date {
            grid-row: 2 / 3;
            grid-column: 2 / 3;
        }
    }

    .promo-banners-wrapper {
        grid-template-columns: minmax(0, 1fr);

        .promo-banner {
            justify-content: flex-start;
            padding-left: 12px;
            gap: 10px;
        }
    }
    
    .detail-comments-header {
        b {
            font-size: 20px !important;
        }

        .stars-rating {
            width: 90px !important;

            ul {
                width: 90px !important;

                li:after {
                    font-size: 15px !important;
                }
            }
        }
    }

    .shop-header {
        top: -105px;

        &:has(.top-bar) {
            top: -130px;
        }
    }

    #detail-traits {
        display: block;

        tbody, tr, td {
            display: block;
            width: 100%;
        }

        td {
            padding-inline: 0 !important;

            &:nth-child(1) {
                text-align: left;
                border-bottom: 0;
                padding-bottom: 0;
            }

            &:nth-child(2) {
                padding-bottom: 8px;
                padding-top: 3px;
            }
        }

        tr {
            padding: 0;

            &:first-child {
                td:nth-child(1) {
                    padding-top: 0 !important;
                }
            }

            &:last-child {
                td:nth-child(2) {
                    padding-bottom: 0 !important;
                }
            }
        }
    }

    .header-location-open {
        width: 100%;
        max-width: none !important;

        .location-text {
            text-align: center;

            b {
                font-size: 14px;
            }
        }

        .map-wrapper {
            width: 100% !important;
        }
    }

    .category-desc-button {
        height: 22px;
        padding: 10px 6px;
        font-size: 12px;
    }

    .buy-button,
    .return-button {
        font-size: 15px !important;
    }

    .alternating-banner
    figure:only-child {
        width: 100%;
    }

    .detail-adding {
        flex-direction: column;
        align-items: stretch;

        > * {
            width: 100% !important;
        }

        .detail-amount {
            margin-bottom: 8px;

            button {
                width: 35%;

                &:before {
                    left: calc(50% - 8px) !important;
                    width: 14px;
                }

                &:after {
                    left: calc(50% - 2px) !important;
                    top: calc(50%) !important;
                    height: 14px !important;
                }
            }
        }

        .buy-button {
            margin: 0;
        }
    }
}