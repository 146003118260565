// IMPORTS
@import (reference) "../settings.less";

@media (max-width: 1100px) {
    // CART
    .check-person {
        margin-top: 20px;
    }

    .payment-channels-modal .goshop-modal .wrapper {
        gap: 10px;
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    .cart-final-boxes {
        display: flex;
        flex-direction: column;
    }

    .checkbox-inline {
        padding: 0 0 15px;
    }

    .checkbox-wrapper {
        padding: 10px;
    }

    .cart-checkbox-main-wrapper .has-many-checkboxes .cart-check-all {
        margin-bottom: 0;
    }

    .cart-checkbox-main-wrapper .has-many-checkboxes .cart-check-all ~ * {
        padding-left: 10px;
    }

    .fields-required-text {
        padding-top: 5px;
    }

    .oauth-signup {
        gap: 10px;
    }

    .oauth-signup > span {
        padding: 20px 0 0;
    }

    .oauth-signup > a.confirm-button {
        font-size: 0;
        padding: 0;
        width: 50px;
        min-width: 0;

        svg {
            margin: 0 !important;
        }
    }

    .field-wrapper:not(.checking-reg),
    .to-show .field-wrapper:last-of-type {
        margin-bottom: 15px !important;
    }

    // OTHER
    #cookies-license {
        bottom: 0;
        right: 0;
        width: 100%;
        max-width: none;
        padding: 10px 10px 10px 80px;

        &:before {
            left: 18px;
        }
    }

    .form-modal .goshop-modal {
        width: 90vw;
        max-width: none;
    }

    .availability-modal-inner {

        .img-wrapper {
            display: none;
        }
    }

    .newsletter-cookie-modal {

        .img-wrapper {
            display: none;
        }

        .text-wrapper {
            padding: 30px 10px;
        }
    }

    .order-detailed-info .item-box-small,
    .order-detailed-info .item-box-medium {
        flex-basis: 100%;
        border-right: 0;
    }

    #detail-traits td {
        padding: 8px 15px;
    }

    // SHOP SPECIFIC //////////////////////////////////////////////////////////////

    @media (max-width: 630px) {
        :root {
            --prodGap: 10px;
        }
    }

    .gs-tabs .products-list .product:nth-child(4n) {
        display: none;
    }

    .mainpage-greeting-wrapper {
        flex-direction: column;

        .main-banner {
            width: 100%;
        }
    }

    .shop-header-container {

        .header-tools-wrapper {
            gap: 14px;
        }
    }

    .header-bar {
        gap: 10px;

        .header-bar-left {
            gap: 16px;
        }

        .header-bar-right {
            gap: 12px;
        }
    }

    @media (max-width: 780px) {
        .header-bar .header-bar-left {
            gap: 8px;

            .email {
                display: none !important;
            }
        }
    }

    .main-banner .text-wrapper {
        bottom: 16px;
        left: 16px;

        b {
            font-size: 18px;
        }
    }

    .promo-banners-wrapper .promo-banner {
        padding: 12px 8px;
        gap: 8px;

        figure {
            width: 28px;
        }

        b {
            font-size: 15px;
        }
    }

    .swiper-button-prev,
    .swiper-button-next {
        width: 28px;
        height: 28px;
        margin-top: -14px;

        &:after {
            font-size: 14px;
        }
    }

    .product {
        gap: 10px;
    }

    @media (max-width: 800px) {
        .product .product-bottom-wrapper {
            .grid();
            grid-template-columns: minmax(0, 74px) minmax(0, 1fr);

            .product-price {
                grid-column: span 2;
                justify-self: center;
                display: block;
                height: auto;
                padding-bottom: 8px;

                del {
                    margin-left: 6px;
                }
            }

            .product-add {
                width: 100%;

                &.disabled {
                    grid-column: span 2;
                }
            }
        }
    }

    .alternating-banner .text-wrapper {

        > b {
            font-size: 20px;
        }

        > div {
            padding-bottom: 12px;
        }
    }

    .dropdown-button,
    .filter-trigger {
        width: initial;
        min-width: 195px;
    }

    #detail-tabs
    nav > button {
        font-size: 22px;
    }

    .alternating-banner .text-wrapper >
    button {
        padding: 6px 12px;
        font-size: 12px;
    }

    .pagination
    a:after {
        font-size: 16px;
    }

    @media (max-width: 900px) {
        .alternating-banner .text-wrapper:only-child {
            width: 100%;
        }

        .alternating-banner
        figure:only-child {
            width: 70%;
        }
    }

    #detail-images .swiper-button-prev,
    #detail-images .swiper-button-next {
        width: 40px;

        &:after {
            font-size: 20px;
        }
    }
}